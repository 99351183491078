<template>
  <div class="page">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>当前位置</el-breadcrumb-item>
        <el-breadcrumb-item to>职业测评</el-breadcrumb-item>
        <el-breadcrumb-item>MBTI测评</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="banner">
      <img style="width:100%" src="@/assets/img/ceshi.jpg" alt>
    </div>
    <div v-if="!show">
      <div class="title">欢迎使用“MBTI测评”！</div>
      <div class="f16">
        <p class="f20 mb10">正式测评前请认真阅读以下指导语：</p>
        <p>1. 本测评总共{{ len }}题，建议测评时长不超过 15 分钟；</p>
        <p>2. 本测评全部为单项选择题，所有答案无对错好坏之分，请选择最符合自身情况的选项。难以抉择的选项建议第一感觉选择，不宜作过多思考；</p>
        <p>3. 测评过程中请确保环境安静舒适，不被打扰；</p>
        <p>4. 测评结果将作为专业选择的重要参考依据，关乎个人前途，请务必认真完成。</p>
        <p>5. MBTI职业性格测试是国际最为流行的职业人格评估工具，作为一种对个性的判断和分析，是一个理论模型，从纷繁复杂的个性特征中，归纳提炼出4个关键要素——动力、信息收集、决策方式、生活方式，进行分析判断，从而把不同个性的人区别开来。</p>
      </div>
      <span class="btn" @click="start">开始测评</span>
    </div>

    <div v-else>
      <div class="title">
        <el-row>
          <el-col :span="6">MBTI测评</el-col>
          <el-col :span="3" :offset="15">
            <span class="f16">用时：{{ timeStr }}</span>
          </el-col>
        </el-row>
      </div>
      <div class="f16">
        <div v-for="(item,i) in list" :key="i" style="padding: 0 30px">
          <div v-if="i===curt">
            <p class="num f20">
              <span>{{ curt+1 }}</span>
              /{{ len }}
            </p>
            <p class="question f26">{{ i+1 }}、{{ item.Q }}</p>
            <div class="answers">
              <span class="answer" @click="doClick(i,item.S1)">{{ item.A1 }}</span>
              <span class="answer" @click="doClick(i,item.S2)">{{ item.A2 }}</span>
            </div>
          </div>
        </div>
      </div>
      <el-progress :percentage="num" status="success" style="margin-top:20px" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Test",
  props: ["type"],
  data() {
    return {
      content: "",
      show: false,
      num: 0,
      list: [],
      len: 0,
      curt: 0,
      AnswerData: {
        hr_email: "18537129969",
        test_email: "1100242",
        tester_name: this.userInfo ? this.userInfo.user_name : "游客",
        host: "",
        code: "",
        checkcode: "51ECS7OIFOZAOGM3ZK",
        feishi: "300",
        tishu: 28,
        sex: "男",
      },
      time: 0,
      timeStr: "00分00秒",
      timer: null,
    };
  },
  mounted() {
    this.AnswerData.test_name = this.userInfo
      ? this.userInfo.user_name
      : "游客";
    this.$fecth
      .get("Appraisal/getAppraisal", {
        params: {
          url: "http://www.apesk.com/lee/json/mbti/dati_hr_28_mNew_json.asp",
          hr_email: "18537129969",
          checkcode: "51ECS7OIFOZAOGM3ZK",
        },
      })
      .then((res) => {
        this.list = res;
        this.len = res.length;
      });
  },
  methods: {
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    start() {
      this.show = true;
      if (this.timer) return false;
      this.timer = setInterval(() => {
        this.time++;
        if (this.time > 59) {
          const s = this.checkTime(this.time % 60);
          const m = this.checkTime(Math.floor(this.time / 60));
          this.timeStr = m + "分" + s + "秒";
        } else {
          const num = this.checkTime(this.time);
          this.timeStr = "00分" + num + "秒";
        }
      }, 1000);
    },
    doClick(i, val) {
      this.AnswerData["answer" + (i + 1)] = val;

      if (i + 1 >= this.list.length) {
        clearInterval(this.timer);
        this.timer = null;
        this.$fecth
          .post("user_info/postAppraisal", {
            url: "http://www.apesk.com/lee/json/mbti/submit_mbti_conn_mm_json.asp",
            answer_url:
              "http://www.apesk.com/mensa/common_report_getid/mbti1_report_hr.asp",
            name: "MBTI测评",
            ParamName: "AnswerData",
            AnswerData: JSON.stringify(this.AnswerData) + "}",
          })
          .then((res) => {
            window.open(res, "_blank");
            this.$router.push("/ucenter/testReport");
          });
      } else {
        this.curt = i + 1;
        this.num = (this.curt / this.len) * 100;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  min-height: 1000px;


  .title {
    color: #333333;
    font-size: 18px;
    padding: 10px 0;
    border-bottom: 2px solid #f2f2f2;
  }

  .f26 {
    font-size: 16px;
  }

  .f16 {
    margin-bottom: 20px;
    font-size: 16px;
    color: #666666;

    p {
      margin-top: 10px;
    }
  }

  .f20 {
    font-size: 16px;
  }

  .btn {
    display: inline-block;
    background: #1787e0;
    height: 40px;
    width: 160px;
    line-height: 40px;
    margin-left: 1000px;
    text-align: center;
    color: #fff;
    border-radius: 4px;
    margin-top: 30px;
  }

  .answers {
    margin-top: 40px;

    .answer {
      display: inline-block;
      padding: 10px 40px;
      border: 2px solid #f2f2f2;
      border-radius: 6px;
      color: #454545;
      font-size: 20px;
      margin-right: 30px;

      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
}
</style>
